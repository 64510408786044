@import "src/scss/module";

.SocialLink {
  background-color: #{$green};
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 18px;
    height: 19px;
    transition: #{$transition-base};
  }

  &:hover {
    img {
      scale: 0.8;
    }
  }
}
